import React from 'react';
import './Projects.css';
import './Section.css';

function Projects() {
  return (
    <div>
      <section id="Projects">
        <h2>Projects</h2>
        {/* 1. GDSC project */}
        <div className="project">
          <img src="/image/gdsc_project.png" alt="gdsc project"></img>
          <div className="project-text-container">
            <h3><i className="fa fa-circle mint"></i> Web-Dev for Beginners</h3>
            <p>
              As part of the web-dev subcommittee of GDSC USYD, I
              organised <a className="mint" href="https://www.linkedin.com/posts/angie-leephokanon_thats-a-wrap-on-the-google-developer-student-activity-7052516324639453184-DXbi?utm_source=share&utm_medium=member_desktop"
              target="_blank">
              workshops</a> which guided over 400 beginners to build a portfolio website with HTML, CSS and JavaScript. 
            </p>
          </div>
        </div>
        {/* 2. Art Portfolio Project */}
        <div className="project">
          <img src="/image/art_project.png" alt="art project"></img>
          <div className="project-text-container">
            <h3><i className="fa fa-circle purple"></i> Art Collection Website</h3>
            <p>
              This <a className="purple" href="https://angieleeart.web.app/" target="_blank">website</a> displays my paintings. I initially made it to practice
              developing with HTML and CSS to prepare for a demonstrator role at USYD. Feel free to check it out and share your favourite painting with me!
            </p>
          </div>
        </div>
        {/* 3. Design Project */}
        <div className="project">
          <img src="/image/design_project.png" alt="design project"></img>
          <div className="project-text-container">
            <h3><i className="fa fa-circle mint"></i> Business Website Design </h3>
            <p>
              I designed a website for a local home renovation business. The design
              has simple and minimalistic elements which speaks to the target market.
              You can see the prototype <a className="mint" href="https://qxb-dev.web.app/" target="_blank">here</a>.
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Projects
