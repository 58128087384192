import React, {useState} from 'react'
import './Navbar.css';
import MenuItem from './MenuItem';

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  return (
    <>
        {/* <nav className="navbar">
            <div className="navbar-container">
                <Link to="/" className="navbar-logo">
                  <i className="fa fa-circle mint"></i> Angie Lee 
                </Link>
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              <li className="nav-item" onClick={closeMobileMenu}>
                <Link to="/" className='nav-links' >
                  Home
                </Link>
              </li>
              <li className="nav-item" onClick={closeMobileMenu}>
                <Link to="/projects" className='nav-links'>
                  Projects
                </Link>
              </li>
              <li className="nav-item" onClick={closeMobileMenu}>
                <Link to="/about" className='nav-links'>
                  About
                </Link>
              </li>
              <li className="nav-item" onClick={closeMobileMenu}>
                <Link to="/fun" className='nav-links'>
                  Fun
                </Link>
              </li>
            </ul>
            <div className='menu-icon' onClick={handleClick}>
              <i className={click ? 'fa fa-times' : 'fa fa-bars'} />
            </div>
        </nav> */}
        <nav className="navbar">
            <div className="navbar-container navbar-logo">
                <i className="fa fa-circle mint"></i> Angie Lee 
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              <li className="nav-links" onClick={closeMobileMenu}>
                <MenuItem itemName={'Home'}/>
              </li>
              <li className="nav-links" onClick={closeMobileMenu}>
                <MenuItem itemName={'Projects'}/>
              </li>
              <li className="nav-links" onClick={closeMobileMenu}>
                <MenuItem itemName={'About'}/>
              </li>
              <li className="nav-links" onClick={closeMobileMenu}>
                <MenuItem itemName={'Fun'}/>
              </li>
            </ul>
            <div className='menu-icon' onClick={handleClick}>
              <i className={click ? 'fa fa-times' : 'fa fa-bars'} />
            </div>
        </nav>
    </>
  )
}

export default Navbar
