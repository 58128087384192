import React from 'react'
import './About.css';
import './Section.css';

function About() {
  return (
    <div>
      <section id="About">
        <h2>About</h2>
        <div className="about-container">
          <img src="/image/my_photo_circle.png" alt="my photo"></img>
          <div className="about-text-container">
            <h3>Nice to meet you, I'm Angie</h3>
            <h4> <i className="fa fa-circle purple"> </i>A bit about me</h4>
            <p className="p-margin-bottom">
              I am a Software Engineering student at the University of Sydney.
              With my love for art and coding, I fell in love with frontend web development.
              At university, I have completed many projects using Java, Python, C
              and SQL.
            </p>
            <p>
            Currently, I am upskilling myself by exploring multiple frontend frameworks and libraries.
              This webpage you're on right now is actually my first work made using React!
            </p>
          </div>
        </div>
        <div id="second-row" className="about-container">
          <div className="about-text-container">
            <h4><i className="fa fa-circle purple"></i>Goals</h4>
            <p className="p-margin-bottom">
              I am currently looking for a software engineering internship opportunity. With my technical skills paired with my strong
              interpersonal skills, I believe that I am a valuable member of any project team.
            </p>
            <p>
             I would love to hear from you. You can <a href = "mailto: angiepunyapa@gmail.com" className="purple">email</a> me
              or find me on <a href="https://linkedin.com/in/angie-leephokanon" target="_blank" className="purple">LinkedIn</a>.
            </p>
          </div>
          <div className="about-text-container">
            <h4><i className="fa fa-circle mint"></i>Personal</h4>
            <p>
              Despite studying in Sydney, I actually live in the Central Coast. Why do I still live here?
              Well, apart from the crazy expensive rental costs in Sydney, the coast is actually
              so amazing it is hard to leave it behind! Plus, I have a lovely pet greyhound at home. 
            </p>
          </div>
          {/* <div className="about-text-container">
            <h4><i className="fa fa-circle mint"></i>Connect</h4>
            <p>
             I would love to hear from you. You can <a href = "mailto: angiepunyapa@gmail.com">email</a> me
              or find me on <a href="linkedin.com/in/angie-leephokanon" target="_blank">LinkedIn</a>
            </p>
          </div> */}
        </div>
      </section>
    </div>
  )
}

export default About
