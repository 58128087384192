import React from 'react';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import Projects from './components/Projects';
import About from './components/About';
import Fun from './components/Fun';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <>
      <Router>
        <Navbar/>
        <Routes>
          <Route path='/' exact />
        </Routes>
      </Router>
      <HeroSection/>
      <Projects/>
      <About/>
      <Fun/>
    </>
  );
}

export default App;
