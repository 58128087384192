import React from 'react'
import './HeroSection.css';

function HeroSection() {
  return (
    <div id="Home" className='hero-container'>
      <img src="/image/me_illus.png" alt="my illustration"></img>
      <div className='hero-text-container'>
        <div className='top-text'>
            <h1>Hi. I'm Angie</h1>
            <h3>An Aspiring Software Engineer</h3>
        </div>
        <div className='bottom-text'>
            <p>
                <i className="fa fa-circle mint"></i> Passionate frontend designer and 
                developer for user-friendly applications
            </p>
            <p>
                <i className="fa fa-circle purple"></i>An effective team member and leader with experience 
                in multiple engineering projects
            </p>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
