import React from 'react';
import './Section.css';
import './Fun.css';
import Emoji from './Emoji.js';

function Fun() {
  const correctAnswers = ['hot choccie', 'yes', 'both', 'narnia']

  function scoreCalc(userAnswer) {
      if(userAnswer == null || userAnswer.length < 0) return 0;
      let totalScore = 0;
      for(let i = 0; i < correctAnswers.length; i++) {
          if(userAnswer[i] === correctAnswers[i]) {
              totalScore++;
          }
      }
      return totalScore;
  }

  function determineIfWin(score) {
      if(score === correctAnswers.length) return true;
      return false;
  }

  let currentQuestion = 0;

  let userName = null;
  const answers = [];

  //this function displays the specified question in the form
  function showQuestion(n) {

      //reset error message to empty
      document.getElementById('error_message_container').textContent = ""

      let questions = document.getElementsByClassName("surveyQuestion");
      //console.log(questions);
      
      //console.log(questions[currentQuestion]);
      //questions[n].style.display = "block";

      questions[currentQuestion].setAttribute("style", "display: block")
      
      //edit the previous and next button accordingly
      //first question, no prev button
      if(n === 0) {
          document.getElementById("prevBtn").style.display = "none";
      } else {
          document.getElementById("prevBtn").style.display = "inline";
      }
      //if we are on the last question, no next button
      //it is the submission button instead
      if(n === (questions.length - 1)) {
          document.getElementById("nextBtn").innerHTML = "Submit";
      } else {
          document.getElementById("nextBtn").innerHTML = "Next";
      }
  }

  //this function is called when next/prev button is pressed
  //it figures out which tab to display
  function nextPrev(n) {
      let questions = document.getElementsByClassName("surveyQuestion");

      let valid = true;
      if(currentQuestion === 0) {
          //if it's the first question, validate text input
          valid = validateNameInput();
      } else {
          //validate if a radio button has been pressed
          valid = validateRadioButton();
      }
      //don't let user go to next question
      if(n === 1 && !valid) return false;

      //hide the current question
      questions[currentQuestion].setAttribute("style", "display: none");
      //increase or decrease the currentQuestion by 1
      currentQuestion += n;
      //if we are pressing next on the last question,
      //it is actuall submit being pressed
      if(currentQuestion >= questions.length) {
          //the form will get submitted
          document.getElementById("prevBtn").style.display = "none";
          document.getElementById("nextBtn").style.display = "none";
          //document.getElementById("surveyForm").submit();
          outputAnswer();
          return false;
      }
      //otherwise, display the correct tab
      showQuestion(currentQuestion);
  }

  //function for checking validation of text input field
  //if user hasn't input any text before clicking next then
  //we turn the field red
  function validateNameInput() {
      let textField = document.getElementById("userName");
      let userNameInput = document.getElementById("userName").value;
      if(userNameInput === "") {
          //console.log("no user name given");
          document.getElementById('error_message_container').textContent = "You must give an input to move on"
          textField.className += "invalid";
          return false;
      }
      userName = userNameInput;
      return true;
  }

  //only used for questions 2 onwards
  //n is the question number
  function validateRadioButton() {
      const questions = document.getElementsByClassName("surveyQuestion");
      const currentQ = questions[currentQuestion];
      const radioButtons = currentQ.getElementsByTagName('input');
      for(let i = 0; i < radioButtons.length; i++) { 
          if(radioButtons[i].checked) {
              answers[currentQuestion - 1] = radioButtons[i].value;
              console.log(answers)
              return true;
          }
      }
      //add an error message
      document.getElementById('error_message_container').textContent = "You must choose one option to move on"
      return false;
  }

  function outputAnswer() {
      const quizScore = scoreCalc(answers);
      const h1 = document.getElementById("introduction").querySelectorAll("h1");
      const p = document.getElementById("introduction").querySelectorAll("p");
      let winMsg = null;
      let scoreMsg = null;
      let endMsg = null;
      let imgLink = null;
      if(determineIfWin(quizScore)) {
          winMsg = userName + " you are Angie 2.0!"
          scoreMsg = "Your score was " + quizScore + "/4";
          endMsg = "Let's grab a cup of hot choccie :)";
          imgLink = "https://media4.giphy.com/media/bzUwzbxcvJ3XQlcnoi/giphy.gif?cid=ecf05e4768kmau5uy28tbge3ganew57920k91jb7lrfcw3ke&ep=v1_gifs_search&rid=giphy.gif&ct=g";
      } else {
          winMsg = userName + " you're not my clone :("
          scoreMsg = "Your score was " + quizScore + "/4";
          endMsg = "I would still love to have a chat with you!";
          imgLink = "https://media.tenor.com/PIs64JMGjDcAAAAd/sad-cat.gif";
      }
      h1[0].textContent = winMsg;
      p[0].textContent = scoreMsg;
      p[1].textContent = endMsg;
      const imgElement = document.createElement("img");
      imgElement.src = imgLink;
      document.getElementById("introduction").appendChild(imgElement);
  }

  function initialise() {
    document.getElementById("startBtn").style.display = "none";
    document.getElementById("nextBtn").style.display = "inline";

    showQuestion(currentQuestion);

    document.getElementById("prevBtn").addEventListener("click", () => {nextPrev(-1)});
    document.getElementById("nextBtn").addEventListener("click", () => {nextPrev(1)});
  }

  return (
    <div>
      <section id="Fun">
        <h2>Fun</h2>
        <div className="quiz">
          <form name="surveyForm">
            <div id="introduction">
                <h1>Are you Angie 2.0?</h1>
                <p>This is just a fun little game to see how alike we are.</p>
                <p>I would love to have a chat with you about anything tech or career opportunities. 
                  So if you get 4/4 on this quiz, I'll treat you to coffee!</p>
            </div>
            <div id="q_1" className="surveyQuestion">
                <h3>Firstly, tell me your name</h3>
                <input id="userName" type="text" name="firstName" autofocus placeholder="Your name"/>
            </div>
            <div id="q_2" className="surveyQuestion radioButtons">
                <h3><Emoji symbol="🍵" label="tea"/> What's your favourite drink?</h3>
                <input type="radio" id="coffee" name="fav_drink" value="coffee"/>
                <label for="coffee">Coffee</label><br/>
                <input type="radio" id="tea" name="fav_drink" value="tea"/>
                <label for="tea">Tea</label><br/>
                <input type="radio" id="hot_choccie" name="fav_drink" value="hot choccie"/>
                <label for="hot_choccie">Hot Choccie</label><br/>
            </div>
            <div id="q_3" className="surveyQuestion radioButtons">
                <h3><Emoji symbol="🍕" label="pizza"/> Pineapple on pizza?</h3>
                <input type="radio" id="yes" name="pizza" value="yes"/>
                <label for="yes">Yes!</label><br/>
                <input type="radio" id="no" name="pizza" value="no"/>
                <label for="no">No :P</label><br/>
            </div>
            <div id="q_4" className="surveyQuestion radioButtons">
                <h3><Emoji symbol="🐶" label="dog"/> Cat or dog person, or both?</h3>
                <input type="radio" id="cat" name="fav_drink" value="cat"/>
                <label for="cat">Cat</label><br/>
                <input type="radio" id="dog" name="fav_drink" value="dog"/>
                <label for="dog">Dog</label><br/>
                <input type="radio" id="both" name="fav_drink" value="both"/>
                <label for="both">Both</label><br/>    
            </div>
            <div id="q_5" className="surveyQuestion radioButtons">
                <h3><Emoji symbol="🎞" label="film"/> Favourite movie franchies</h3>
                <input type="radio" id="harrypotter" name="fav_movie" value="harrypotter"/>
                <label for="harrypotter">Harry Potter</label><br/>
                <input type="radio" id="hungergames" name="fav_movie" value="hungergames"/>
                <label for="hungergames">The Hunger Games</label><br/>
                <input type="radio" id="narnia" name="fav_movie" value="narnia"/>
                <label for="narnia">The Chronicles of Narnia</label><br/>
                <input type="radio" id="lordoftherings" name="fav_movie" value="lordoftherings"/>
                <label for="lordoftherings">The Lord of the Rings</label><br/>    
            </div>
            <div id="error_message_container"></div>
            <div id ="box" className="buttonsContainer">
                <button type="button" id="startBtn" onClick={initialise}>Start</button>
                <button type="button" id="prevBtn">Previous</button>
                <button type="button" id="nextBtn">Next</button>
            </div>
          </form>
        </div>
      </section>
    </div>
  )
}

export default Fun
