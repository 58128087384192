/*
 * A single menu item
 * I deconstruct props to provide more readable code, allowing
 * any future coders to see exactly what props are expected
 */
const MenuItem = ({ itemName }) => {
  /*
   * Store our anchorTarget in state
   * We do not set it here, preferring to wait for after the component
   * is mounted to avoid any errors
   */
  // const [anchorTarget, setAnchorTarget] = useState(null);

  /*
   * When the component mounts and/or updates, set our AnchorTarget based
   * on the itemName
   */
  // useEffect(() => {
  //   setAnchorTarget(document.getElementById(itemName));
  // }, [itemName]);

  /*
   * Where all the magic happens -- scrollIntoView on click
   */

  // const anchorTarget = document.getElementById(itemName);


  const handleClick = event => {
    event.preventDefault();
    if(itemName !== 'Home') {
      document.getElementById(itemName).scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      document.getElementById(itemName).scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    
  };

  /*
   * Return the MenuItem as JSX
   * Remember to set your ariaLabel for accessability!
   */
  return (
    
    <a href={`#${itemName}`}
        onClick={handleClick}
        ariaLabel={`Scroll to ${itemName}`}>
      {itemName}
    </a>
    
  );
};

export default MenuItem;

//https://medium.com/the-coders-guide-to-javascript/smooth-scrolling-anchor-menu-in-reactjs-175030d0bce2